import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/2d4007142213379.6262728865cfc.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/2d4007142213379.6262728865cfc.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/e773b6142213379.6262728869577.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/e773b6142213379.6262728869577.jpg",
    width: 1920,
    height: 1333,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/b4c174142213379.6262728867dd6.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/b4c174142213379.6262728867dd6.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/67406b142213379.626272886857a.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/67406b142213379.626272886857a.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/456789142213379.62627288675cb.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/456789142213379.62627288675cb.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/618ed0142213379.6262779bdcbec.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/618ed0142213379.6262779bdcbec.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/c079be142213379.6262779bdd33c.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/c079be142213379.6262779bdd33c.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/78b294142213379.626272886664a.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/78b294142213379.626272886664a.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/c5c3cc142213379.6262779bddbf8.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/c5c3cc142213379.6262779bddbf8.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/88d036142213379.6262728866f08.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/88d036142213379.6262728866f08.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/860381142213379.6262728868ec1.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/860381142213379.6262728868ec1.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Abamor Natural Olive Oil - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/9afce4142160597.626173a91e6cd.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/9afce4142160597.626173a91e6cd.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/39408b142160597.626173a91ffa4.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/39408b142160597.626173a91ffa4.jpg",
    width: 1920,
    height: 3411,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/dc3af2142160597.626173a9205d2.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/dc3af2142160597.626173a9205d2.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/15b63f142160597.626173a920c28.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/15b63f142160597.626173a920c28.jpg",
    width: 1920,
    height: 3411,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/9930cb142160597.626173a91f69a.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/9930cb142160597.626173a91f69a.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/121c71142160597.626173a91ef7f.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/121c71142160597.626173a91ef7f.jpg",
    width: 1920,
    height: 3411,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/6112ec142160597.6261765b17f56.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/6112ec142160597.6261765b17f56.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/86201b142160597.6261765b14cf4.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/86201b142160597.6261765b14cf4.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/ef5f0e142160597.6261765b17180.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/ef5f0e142160597.6261765b17180.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/83bdbf142160597.6261765b15b06.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/83bdbf142160597.6261765b15b06.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/8bc16d142160597.6261765b177bb.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8bc16d142160597.6261765b177bb.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/c998be142160597.6261765b1530d.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/c998be142160597.6261765b1530d.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/677c56142160597.6261765b16998.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/677c56142160597.6261765b16998.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/da7407142160597.6261765b16158.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/da7407142160597.6261765b16158.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Catalog", title: "Catalog Shooting" },
    ],
    caption: "Bohça Store - March 2021",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/2f732f142165137.62618201a47d1.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/2f732f142165137.62618201a47d1.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/a1d2c0142165137.62618201a774f.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/a1d2c0142165137.62618201a774f.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/3f456c142165137.62618201aab6a.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/3f456c142165137.62618201aab6a.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/fdd9e4142165137.62618201a8400.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/fdd9e4142165137.62618201a8400.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/8598d7142165137.62618201ab53c.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8598d7142165137.62618201ab53c.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/f22ebb142165137.62618201a7e28.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/f22ebb142165137.62618201a7e28.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/d7919f142165137.62618201abdeb.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/d7919f142165137.62618201abdeb.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/646666142165137.62618201a9552.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/646666142165137.62618201a9552.jpg",
    width: 1920,
    height: 3411,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/4cf8d7142165137.62618201a5202.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4cf8d7142165137.62618201a5202.jpg",
    width: 1920,
    height: 3411,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/60f13e142165137.62618201a9f73.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/60f13e142165137.62618201a9f73.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/4dfe1f142165137.62618201a8cc4.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4dfe1f142165137.62618201a8cc4.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/b4534d142165137.62618201a6b85.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/b4534d142165137.62618201a6b85.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/328115142165137.62618201a5a44.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/328115142165137.62618201a5a44.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/8c6d1e142165137.62618201a62a9.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8c6d1e142165137.62618201a62a9.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Product", title: "Product Shooting" },
    ],
    caption: "Ceramic Products - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/0c9e7c142163055.62617f51eca7d.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/0c9e7c142163055.62617f51eca7d.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Std - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/064d7a142163055.62617f51ed459.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/064d7a142163055.62617f51ed459.jpg",
    width: 1920,
    height: 3411,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Std - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/9d89bf142163055.62617f51eb7bb.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/9d89bf142163055.62617f51eb7bb.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Std - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/900cf7142163055.62617f51ec136.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/900cf7142163055.62617f51ec136.jpg",
    width: 1920,
    height: 3411,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Std - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/4a067a142163055.62617f51eac0c.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4a067a142163055.62617f51eac0c.jpg",
    width: 1920,
    height: 1081,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Std - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/daedec142163055.62617b6eb85a9.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/daedec142163055.62617b6eb85a9.jpg",
    width: 1400,
    height: 933,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Std - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/5eba56142163055.62617b6ebd20c.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/5eba56142163055.62617b6ebd20c.jpg",
    width: 1400,
    height: 2100,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Std - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/a0b764142163055.62617b6eb8d1c.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/a0b764142163055.62617b6eb8d1c.jpg",
    width: 1400,
    height: 788,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Std - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/1c15ac142162483.62617983dc963.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/1c15ac142162483.62617983dc963.jpg",
    width: 1920,
    height: 1280,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Street Fashion - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/32e984142162483.62617983dd567.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/32e984142162483.62617983dd567.jpg",
    width: 1920,
    height: 2880,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Street Fashion - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/700574142162483.62617983e1531.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/700574142162483.62617983e1531.jpg",
    width: 1920,
    height: 2880,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Street Fashion - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/d34d43142162483.62617983e0694.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/d34d43142162483.62617983e0694.jpg",
    width: 1920,
    height: 2880,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Street Fashion - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/f5e372142162483.62617983e2a75.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/f5e372142162483.62617983e2a75.jpg",
    width: 1920,
    height: 2880,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Street Fashion - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/5172b8142162483.62617983deba1.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/5172b8142162483.62617983deba1.jpg",
    width: 1920,
    height: 2880,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Street Fashion - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/f56e67142162483.62617983e1ff2.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/f56e67142162483.62617983e1ff2.jpg",
    width: 1920,
    height: 2880,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Street Fashion - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/175e90142162483.62617983df57a.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/175e90142162483.62617983df57a.jpg",
    width: 1920,
    height: 2880,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Street Fashion - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/7e3d2d142162483.62617983dfece.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/7e3d2d142162483.62617983dfece.jpg",
    width: 1920,
    height: 2880,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Street Fashion - April 2022",
  },
  {
    src: "https://mir-cdn.behance.net/v1/rendition/project_modules/disp/4ca6e3142162483.62617983de165.jpg",
    original: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4ca6e3142162483.62617983de165.jpg",
    width: 1920,
    height: 2880,
    tags: [
      { value: "Model", title: "Model Shooting" },
    ],
    caption: "Street Fashion - April 2022",
  },
];
