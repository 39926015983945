import { Avatar, Box, Container, Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import { BehanceIcon, InstagramIcon, LinkedInIcon } from "./icons";

const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'black', color: 'white', bottom: 0, width: '100%', py: 5 }}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid container item xs={12} md={4} direction={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center">
            <Avatar
              alt="Profil Fotoğrafı"
              src="./Avatar.jpg"
              sx={{ width: 150, height: 150, mr: 3 }}
            />
            <h1>
              Ben Halil<span className="animate-wave">👋</span>
            </h1>
          </Grid>
          <Grid item xs={12} md={8} sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                fontWeight: 'normal',
                textAlign: { xs: 'center', md: 'left' },
                fontSize: '1rem',
                mx: 3,
                gridColumn: { xs: 'span 2', md: 'span 1' },
                my: 'auto',
              }}
            >
              <Typography variant="body1" mb={4}>
                2015'ten beri video çekimi, kurgu, motion graphic, fotoğrafçılık ve fotoğraf işleme işlerinde deneyimli bir serbest çalışanım. Adobe Premiere Pro, After Effects, Photoshop ve Lightroom uygulamalarına hakimim.
              </Typography>
              <Typography variant="body1" mb={4}>
                Çalışmalarımda yaratıcı ve yenilikçi olmaya önem gösteriyorum. Her projeye farklı bir yaklaşım benimsiyorum ve her zaman en iyi sonucu elde etmek için çalışıyorum. Ayrıca ekip çalışmasına ve hızlı öğrenmeye de hazırım.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" mb={2}>
          <IconButton
            href="https://www.instagram.com/artrography"
            target="_blank"
            color="inherit"
            sx={{
              '&:hover': {
                transform: 'scale(1.2)',
                transition: 'transform 0.3s',
              },
            }}>
            <SvgIcon>
              <InstagramIcon />
            </SvgIcon>
          </IconButton>
          <IconButton
            href="https://www.linkedin.com/in/halil-ibrahim-g%C3%B6nc%C3%BC"
            target="_blank"
            color="inherit"
            sx={{
              '&:hover': {
                transform: 'scale(1.2)',
                transition: 'transform 0.3s',
              },
            }}>
            <SvgIcon>
              <LinkedInIcon />
            </SvgIcon>
          </IconButton>
          <IconButton
            href="https://www.behance.net/halilgoncu"
            target="_blank"
            color="inherit"
            sx={{
              '&:hover': {
                transform: 'scale(1.2)',
                transition: 'transform 0.3s',
              },
            }}>
            <SvgIcon>
              <BehanceIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Typography variant="body2" align="center">
          © {new Date().getFullYear()} Tüm Hakları Saklıdır.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
